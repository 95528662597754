import { FC } from 'react';
import { Button, Typography } from '@mui/material';

import { useNavigationActions } from '../../hooks/history';
import { useTranslation } from '../../utils/i18n';
import { MagicForm } from './components';


export const Magic: FC<{ email: string, setEmail: (email: string) => void }> = function Magic({ email, setEmail }) {

  const { goBack } = useNavigationActions();
  const { t } = useTranslation('auth');

  return (
    <>
      <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
        {t('well_send_magic_link')}
      </Typography>

      <MagicForm email={email} type="submit" EmailInputProps={{ setEmail }} />

      <Button variant='text' fullWidth onClick={goBack} sx={{ padding: 0, pt: 2 }}>{t('back')}</Button>
    </>
  );
}
