import { FC, useMemo } from 'react';
import { Typography, Link, Button } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { useLocation } from 'react-router-dom';

import { landingTrackingVar } from '../../vars/landing-tracking';
import { AppleButton, GoogleButton, TwitterButton, FacebookButton } from './components';
import { Router } from './Container';


export const Test: FC<{ router: Router }> = function Test({ router }) {

  const landingTracking = useReactiveVar(landingTrackingVar);

  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const popup = !!searchParams.get('popup');

  return (
    <>
      {popup &&
        <Typography variant="body1" sx={{ mb: 2 }}>
          Probably in {'"In-app browser"'}
        </Typography>
      }

      {/* Google */}

      {/* Works on web */}
      {/* Works on native */}
      {/* Works on in-app */}
      <GoogleButton>Google Popup + Redirect</GoogleButton>

      {/* Works on web */}
      {/* Works on native */}
      {/* Works on in-app */}
      <GoogleButton variant="text">Google Popup</GoogleButton>

      {/* Works on web */}
      {/* Not working in native due to auth/invalid-cordova-configuration w cordovaPopupRedirectResolver or auth/unauthorized-domain w browserPopupRedirectResolver */}
      {/* Works on in-app */}
      <GoogleButton variant="text" forceRedirect sx={{ color: Capacitor.isNativePlatform() ? 'grey.600' : 'primary.main' }}>Google Redirect</GoogleButton>

      {/* Apple */}

      {/* Works on web */}
      {/* Works on native */}
      {/* Works on in-app */}
      <AppleButton>Apple Popup + Redirect</AppleButton>

      {/* Works on web */}
      {/* Works on native */}
      {/* Works on in-app */}
      <AppleButton variant="text">Apple Popup</AppleButton>

      {/* Works on web */}
      {/* Not working in native due to auth/invalid-cordova-configuration w cordovaPopupRedirectResolver or auth/unauthorized-domain w browserPopupRedirectResolver */}
      {/* Works on in-app */}
      <AppleButton variant="text" forceRedirect sx={{ color: Capacitor.isNativePlatform() ? 'grey.600' : 'primary.main' }}>Apple Redirect</AppleButton>

      {/* Twitter */}

      {/* Works on web  */}
      {/* Works on native after https://github.com/robingenz/capacitor-firebase/issues/99#issuecomment-1152897693 and our fork */}
      {/* Works on in-app */}
      <TwitterButton>Twitter Popup + Redirect</TwitterButton>

      {/* Works on web */}
      {/* Works on native after https://github.com/robingenz/capacitor-firebase/issues/99#issuecomment-1152897693 and our fork */}
      {/* Works on in-app */}
      <TwitterButton variant="text">Twitter Popup</TwitterButton>

      {/* Works on web */}
      {/* Not working in native due to auth/invalid-cordova-configuration w cordovaPopupRedirectResolver or auth/unauthorized-domain w browserPopupRedirectResolver */}
      {/* Works on in-app */}
      <TwitterButton variant="text" forceRedirect sx={{ color: Capacitor.isNativePlatform() ? 'grey.600' : 'primary.main' }}>Twitter Redirect</TwitterButton>

      {/* Facebook */}
      {/* open_rsdpgho_user@tfbnw.net / grandma-peon-HECK */}

      {/* TBD web */}
      {/* TBD native */}
      {/* TBD in-app */}
      <FacebookButton forceRedirect={false}>Facebook Popup + Redirect</FacebookButton>

      {/* TBD web */}
      {/* TBD native */}
      {/* TBD in-app */}
      <FacebookButton variant="text" forceRedirect={false}>Facebook Popup</FacebookButton>

      {/* TBD web */}
      {/* TBD, probably Not working in native due to auth/invalid-cordova-configuration w cordovaPopupRedirectResolver or auth/unauthorized-domain w browserPopupRedirectResolver */}
      {/* TBD in-app */}
      <FacebookButton variant="text" forceRedirect sx={{ color: Capacitor.isNativePlatform() ? 'grey.600' : 'primary.main' }}>Facebook Redirect</FacebookButton>

      <FacebookButton variant="text">Facebook Force Redirect if IAB is detected</FacebookButton>

      {landingTracking.source === 'twitter' &&
        <TwitterButton>Twitter visible on source=twitter</TwitterButton>
      }

      {landingTracking.source === 'facebook' &&
        <FacebookButton>Facebook visible on source=facebook</FacebookButton>
      }

      {!popup &&
        <>
          <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
            Open Browser with app.icecream.club/auth/test?...
          </Typography>

          <Button
            variant="contained"
            sx={{ mb: 1 }}
            fullWidth
            onClick={ () => Browser.open({ url: 'https://app.icecream.club/auth/test?popup=true', presentationStyle: 'popover' }) }
          >
            {'"In-app browser"'}
          </Button>

          <Button
            variant="text"
            sx={{ mb: 2 }}
            fullWidth
            onClick={ () => Browser.open({ url: 'https://app.icecream.club/auth/test?utm_source=twitter&popup=true', presentationStyle: 'popover' }) }
          >
            In-app browser w utm_source=twitter
          </Button>

          <Button
            variant="text"
            sx={{ mb: 2 }}
            fullWidth
            onClick={ () => Browser.open({ url: 'https://app.icecream.club/auth/test?utm_source=facebook&popup=true', presentationStyle: 'popover' }) }
          >
            In-app browser w utm_source=facebook
          </Button>
        </>
      }

      <Typography sx={{ mb: 2, textAlign: 'center' }}>
        <Link fontWeight="500" onClick={() => router('login')}>Login</Link>
      </Typography>
    </>
  );
}
