import { useState, useCallback, FC, SyntheticEvent } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as EmailValidator from 'email-validator';
import { sendPasswordResetEmail } from 'firebase/auth';

import { useTranslation } from '../../utils/i18n';
import { firebaseAuth } from '../../firebase';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useNavigationActions } from '../../hooks/history';
import { useSnackbar } from './hooks/snackbar';
import { EmailInput, MagicForm } from './components';


export const Forgot: FC<{ email: string, setEmail: (email: string) => void }> = function Forgot({ email, setEmail }) {

  const { t } = useTranslation('auth');
  const { track } = useAnalyticsQueued();
  const { goBack } = useNavigationActions();
  const [ loading, setLoading ] = useState<boolean>();
  const { show } = useSnackbar();

  const forgot = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('forgot_password_request', { category: 'auth' });
    setLoading(true);
    sendPasswordResetEmail(firebaseAuth, email, { url: 'https://link.icecream.club/gIhOkintrgb' })
      .then(() => {
        setLoading(false);
        show({ text: 'Check your email for further instructions on how to change your password.', severity: 'info' });
        track('forgot_password_sent', { category: 'auth' });
      })
      .catch((resetError) =>{
        track('forgot_password_error', { category: 'auth', error: resetError.code });
        if (resetError.code === 'auth/missing-email') {
          show({ text: 'Email not found', severity: 'error' });
        } else {
          console.error(resetError);
          show({ text: resetError.message, severity: 'error' });
        }
        setLoading(false);
      });
  }, [ email, show, track ]);

  return (
    <>
      <form onSubmit={forgot}>

        <EmailInput email={email} setEmail={setEmail} />

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loading}
          disabled={ !EmailValidator.validate(email)}
          sx={{ mt: 1 }}
        >
          {t('reset_password')}
        </LoadingButton>

      </form>

      <Divider sx={{ mt: 2, mb: 2 }}>{t('or')}</Divider>

      <MagicForm email={email} EmailInputProps={{ type: 'hidden', setEmail: () => null }} />

      <Typography variant="body1" sx={{ mt: 1 }}>
        {t('well_send_magic_link')}
      </Typography>

      <Button variant='text' fullWidth onClick={goBack} sx={{ padding: 0, pt: 3 }}>{t('back')}</Button>
    </>
  );
}
